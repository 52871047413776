import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-ui-filter-category',
  templateUrl: './ui-filter-category.component.html',
  styleUrls: ['./ui-filter-category.component.scss']
})
export class UiFilterCategoryComponent implements OnInit, OnChanges {

  @Input() form: FormGroup = null;

  @Input() solution: any = null;

  @Input() showItems: string = 'NONE'; //NONE => no se muestra // ITEM => muestra los items // GRAPHIC => muestra las gráficas

  loading: any = {
    superCategories: false,
    measurement: false,
    items: false
  };

  superCategories: any = [
    {
      id: -1,
      name: 'Seleccione'
    }
  ];

  measurements: any = [{
    id: -1,
    name: 'Seleccione'
  }];

  items: any = [];

  filteredItems: Observable<any[]> = of([]);

  constructor(private api: ApiService, private global: GlobalService) { }

  ngOnInit(): void {
    this.listSuperCategories();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.showItems === 'ITEM' || this.showItems === 'GRAPHIC') {
      if (changes.solution.currentValue !== null && changes.solution.currentValue !== -1) {
        this.frmItem = '';
        if (this.showItems === 'ITEM') {
          this.listItems();
        } else {
          this.listGraphics();
        }
      } else {
        this.frmItem = '';
        this.filteredItems = of([]);
      }
    }
  }

  set frmSuperCategory(val) {
    this.form.get('superCategory').setValue(val);
  }
  get frmSuperCategory() {
    return this.form.get('superCategory').value;
  }

  set frmMeasurement(val) {
    this.form.get('measurement').setValue(val);
  }
  get frmMeasurement() {
    return this.form.get('measurement').value;
  }

  set frmItem(val) {
    this.form.get('item').setValue(val);
  }
  get frmItem() {
    return this.form.get('item').value;
  }

  listSuperCategories(): void {
    this.loading.superCategories = true;
    this.api.getSuperCategories().subscribe((data: any) => {
      this.loading.superCategories = false;
      if (this.global.is200(data)) {
        this.superCategories = this.superCategories.concat(data.body);
      }
    }, (data: any) => {
      this.global.handleError(data, false);
    });
  }

  onChangeSuperCategory(event: any): void {

    if (this.showItems === 'ITEM' || this.showItems === 'GRAPHIC') {
      this.frmItem = '';
      this.filteredItems = of([]);
    }

    if (event.value === -1) {
      this.measurements = [
        {
          id: -1,
          name: 'Seleccione'
        }
      ];
      this.frmMeasurement = this.measurements[0].id;
    } else {
      this.listMeasurementCategories(event.value);
    }
  }

  listMeasurementCategories(superCategory: number): void {
    this.measurements = [
      {
        id: -1,
        name: 'Seleccione'
      }
    ];
    this.loading.measurement = true;
    this.api.getMeasurementCategoriesBySuperCategory(superCategory).subscribe((data: any) => {
      if (this.global.is200(data)) {
        this.loading.measurement = false;
        this.measurements = this.measurements.concat(data.body);
      }
    }, (data: any) => {
      this.global.handleError(data, false);
    });
  }

  onChangeMeasurement(event: any): void {

    if (this.showItems === 'ITEM' || this.showItems === 'GRAPHIC') {
      this.frmItem = '';

      if (event.value === -1 || this.solution === null || this.solution === -1) {
        this.filteredItems = of([]);
      } else {
        if (this.showItems === 'ITEM') {
          this.listItems();
        } else {
          this.listGraphics();
        }

      }
    }

  }

  listItems(): void {
    this.loading.items = true;
    const send = {
      measurement: this.frmMeasurement,
      solution: this.solution
    };

    this.api.getItemsByCategorySolution(send).subscribe((data: any) => {
      this.loading.items = false;
      if (this.global.is200(data)) {
        this.items = data.body;

        this.filteredItems = this.form.get('item').valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value.itemName)),
          map(name => (name ? this._filter(name) : this.items.slice())),
        );

      }
    }, (data: any) => {
      this.global.handleError(data);
    });
  }

  listGraphics(): void {
    this.loading.items = true;
    const send = {
      measurement: this.frmMeasurement,
      solution: this.solution
    };

    this.api.getGraphicsByCategorySolution(send).subscribe((data: any) => {
      this.loading.items = false;
      if (this.global.is200(data)) {
        this.items = data.body;

        this.filteredItems = this.form.get('item').valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value.graphName)),
          map(name => (name ? this._filter(name) : this.items.slice())),
        );

      }
    }, (data: any) => {
      this.global.handleError(data);
    });
  }


  displayFn(item: any): string {
    if(item){
      return item.itemName ? item.itemName : item.graphName;
    }else{
      return '';
    }
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.items.filter((option: any) => {
      if (this.showItems === 'ITEM') {
        return option.itemName.toLowerCase().includes(filterValue);
      } else {
        return option.graphName.toLowerCase().includes(filterValue);
      }
    });
  }

}
