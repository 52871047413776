import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-ui-filter-date',
  templateUrl: './ui-filter-date.component.html',
  styleUrls: ['./ui-filter-date.component.scss']
})
export class UiFilterDateComponent implements OnInit {

  @Input() form: FormGroup = null;

  @Output() onDateSelected = new EventEmitter();

  times: any = [
    {
      name: 'Hace 1 hora',
      value: '-1 hour',
      graph: 'now-1h'
    },
    {
      name: 'Hace 7 horas',
      value: '-7 hour',
      graph: 'now-7h'
    },
    {
      name: 'Últimas 24 horas',
      value: '-24 hour',
      graph: 'now-24h'
    },
    {
      name: 'Hace 7 días',
      value: '-7 day',
      graph: 'now-7d'
    },
    {
      name: 'Hace 30 días',
      value: '-30 day',
      graph: 'now-30d'
    },
    {
      name: 'Hace 2 meses',
      value: '-2 month',
      graph: 'now-2M'
    },
    {
      name: 'Hace 6 meses',
      value: '-6 month',
      graph: 'now-6M'
    },
    {
      name: 'Hace 12 meses',
      value: '-1 year',
      graph: 'now-1y'
    },
    {
      name: 'Rango de fechas',
      value: 'RANGE'
    }
  ];

  intervals: any = [
    {
      name: '1 minuto',
      value: 'PT1M'
    },
    {
      name: '2 minutos',
      value: 'PT2M'
    },
    {
      name: '5 minutos',
      value: 'PT5M'
    },
    {
      name: '10 minutos',
      value: 'PT10M'
    },
    {
      name: '15 minutos',
      value: 'PT15M'
    },
    {
      name: '20 minutos',
      value: 'PT20M'
    },
    {
      name: '30 minutos',
      value: 'PT30M'
    },
    {
      name: '1 hora',
      value: 'PT1H'
    },
    {
      name: '6 horas',
      value: 'PT6H'
    },
    {
      name: '12 horas',
      value: 'PT12H'
    },
    {
      name: '1 dia',
      value: 'P1D'
    }
  ];

  START_DATE: string = '';

  END_DATE: string = 'now';

  START_DATE_GRAPH: string = '';

  constructor(private datepipe: DatePipe, private global: GlobalService) { }

  ngOnInit(): void {
    this.frmFrom = this.times[0].value;

    this.frmInterval = this.intervals[3].value;

    this.START_DATE = this.frmFrom;

    this.START_DATE_GRAPH = this.times[0].graph;

    this.onDateSelected.emit({start: this.START_DATE, start_graph:this.START_DATE_GRAPH, end: this.END_DATE, interval: this.frmInterval});
  }

  set frmFrom(val) {
    this.form.get('from').setValue(val);
  }
  get frmFrom() {
    return this.form.get('from').value;
  }

  set frmDateFrom(val) {
    this.form.get('dateFrom').setValue(val);
  }
  get frmDateFrom() {
    return this.form.get('dateFrom').value;
  }

  set frmDateTo(val) {
    this.form.get('dateTo').setValue(val);
  }
  get frmDateTo() {
    return this.form.get('dateTo').value;
  }

  set frmInterval(val) {
    this.form.get('interval').setValue(val);
  }
  get frmInterval() {
    return this.form.get('interval').value;
  }

  onSelectFilterFrom(value: any): void {
    if (value === 'RANGE') {
      this.form.get('dateFrom').setValidators([Validators.required]);
      this.form.get('dateTo').setValidators([Validators.required]);
      this.onDateSelected.emit({start: null, start_graph: null, end: null, interval: this.frmInterval});
    } else {
      this.START_DATE = value;
      this.END_DATE = 'now';
      this.START_DATE_GRAPH = this.times.find(time => time.value === value).graph;
      this.form.get('dateFrom').setValidators([]);
      this.form.get('dateTo').setValidators([]);
      this.form.get('dateFrom').setValue(null);
      this.form.get('dateTo').setValue(null);
      this.onDateSelected.emit({start: this.START_DATE, start_graph: this.START_DATE_GRAPH, end: this.END_DATE, interval: this.frmInterval});
    }
    this.form.get('dateFrom').updateValueAndValidity();
    this.form.get('dateTo').updateValueAndValidity();
  }

  onChangeDateFrom(event: any): void {
    if (event.value) {
      if (this.frmDateTo) {
        this.generateRangeDates();
      } else {
        this.global.showMessage('Ingrese una fecha final válida');
      }
    } else {
      this.global.showMessage('Ingrese una fecha de inicio válida');
    }
  }

  onChangeDateTo(event: any): void {
    if (event.value) {
      if (this.frmDateFrom) {
        this.generateRangeDates();
      } else {
        this.global.showMessage('Ingrese una fecha de inicio válida');
      }
    } else {
      this.global.showMessage('Ingrese una fecha final válida');
    }
  }


  generateRangeDates(): void {
    this.END_DATE = this.datepipe.transform(this.frmDateTo, 'yyyy-MM-dd HH:mm:ss');
    this.START_DATE = this.datepipe.transform(this.frmDateFrom, 'yyyy-MM-dd HH:mm:ss');
    this.START_DATE_GRAPH = this.START_DATE;
    this.onDateSelected.emit({start: this.START_DATE, start_graph: this.START_DATE_GRAPH, end: this.END_DATE, interval: this.frmInterval});
  }

  onSelectFilterInterval(value: any): void {
    this.onDateSelected.emit({start: this.START_DATE, start_graph:this.START_DATE_GRAPH, end: this.END_DATE, interval: value});
  }

}
