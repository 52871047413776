import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-ui-filter-category-group',
  templateUrl: './ui-filter-category-group.component.html',
  styleUrls: ['./ui-filter-category-group.component.scss']
})
export class UiFilterCategoryGroupComponent implements OnInit {

  @Input() form: FormGroup = null;

  @Input() categoriesGroups: any = [];

  @Output() onSelected = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    
  }

  onSelectCategory(value: any): void {
    let filters = [];
    for (let i = 0; i < this.categoriesGroups.length; i++) {
      for (let j = 0; j < this.categoriesGroups[i].categories.length; j++) {
        const category = this.categoriesGroups[i].categories[j];
        for (let k = 0; k < value.length; k++) {
          if(category.id === value[k]){
            filters.push(category);
            break;
          }
        }
      }
    }
    this.onSelected.emit(filters);
  }
}
