import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  private typeCiuList: any = {
    DNI: {
      code: '1',
      name: 'Documento Nacional de Identidad',
      key: 'DNI'
    },
    RUC: {
      code: '6',
      name: 'Registro Unico de Contributentes',
      key: 'RUC'
    }
  };

  private severityList: any = {
    NOT_CLASSIFIED: {
      name: 'DESCONOCIDO',
      code: 0
    },
    INFORMATION: {
      name: 'INFORMACIÓN',
      code: 1
    },
    WARNING: {
      name: 'ADVERTENCIA',
      code: 2
    },
    AVERAGE: {
      name: 'MEDIO',
      code: 3
    },
    HIGH: {
      name: 'ALTO',
      code: 4
    },
    DISASTER: {
      name: 'CRÍTICO',
      code: 5
    }
  };

  private statusTicketList: any = {
    RESOLVED: {
      name: 'SOLUCIONADO',
      code: 0
    },
    PROBLEM: {
      name: 'PROBLEMA',
      code: 1
    }
  };

  public superCategories: any = {
    LATENCY: {
      name: 'Latencia',
      code: 'LATENCY'
    },
    PACKET_LOSS: {
      name: 'Pérdida de paquetes',
      code: 'PACKET_LOSS'
    },
    PERFORMANCE: {
      name: 'Velocidad',
      code: 'PERFORMANCE'
    }
  };

  public reportType: any = {
    GENERAL: {
      name: 'Análisis general por super categoría',
      code: 'GENERAL'
    },
    CATEGORY: {
      name: 'Análisis por categoría de medición',
      code: 'CATEGORY'
    },
    ITEMS: {
      name: 'Análisis específico por item',
      code: 'ITEMS'
    }
  };


  public statusMeasurementCategory: any = {
    VALUE_NONE: 0,
    VALUE_OK: 1,
    VALUE_ERROR: 2
  };

  public statusItem: any = {
    VALUE_NONE: 0,
    VALUE_OK: 1,
    VALUE_ERROR: 2
  };


  /**
   * Obtiene los tipo de identificación como objeto {DNI:{},RUC:{}...}
   */
  getTypeCiuList(): any {
    return this.typeCiuList;
  }

  getSeverityList(): any {
    return this.severityList;
  }

  getStatusTicketList(): any {
    return this.statusTicketList;
  }

  /**
   * Transforma un objeto en un array eliminando sólo la key
   */
  getArray(elements: any): any {
    const list = [];
    for (const [index, value] of Object.entries(elements)) {
      list.push(value);
    }
    return list;
  }

}
