// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'yftel-qoe.yftelperu.com/api2',
  protocol : 'https://',
  mercure : 'https://yftel-qoe.yftelperu.com/hub/.well-known/mercure?topic=',
  description: {
    title: 'Speed Test',
    establishment: {
      //singular: 'Servicio',
      //plural: 'Servicios'
      singular: 'Establecimiento',
      plural: 'Establecimientos'
    },
    solution: {
      //singular: 'Plan contratado',
      //plural: 'Planes contratados'
      singular: 'Solución',
      plural: 'Soluciones'
    }
  },
  listView: 1, //1=> lista, 2=>bloque, 3=>grid
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
