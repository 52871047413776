<form *ngIf="form" [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutGap="1rem grid">

        <div fxFlex="30%" fxFlex.lt-md="50%" fxFlex.xs="100%">

            <ng-container  *ngIf="customers.length === 0">
                <mat-form-field appearance="outline">
                    <mat-label>Cliente <app-mini-loading *ngIf="loading.customers"></app-mini-loading></mat-label>
                    <input type="text" matInput [readonly]="true">
                </mat-form-field>
            </ng-container>

            <ng-container  *ngIf="customers.length > 0">
                <mat-form-field appearance="outline" *ngIf="customers.length === 1">
                    <mat-label>Cliente</mat-label>
                    <mat-select formControlName="customer">
                        <mat-option *ngFor="let c of customers" [value]="c.id">{{c.full}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="outline" *ngIf="customers.length > 1">
                    <mat-label>Cliente</mat-label>
                    <input type="text" matInput formControlName="customer" [matAutocomplete]="auto" (input)="onChange($event)">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelected($event)">
                        <mat-option *ngFor="let c of filteredOptions | async; let index = index" [value]="c">
                            {{c.fullName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>

        </div>

        <div fxFlex="30%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>{{description.establishment.singular}}
                    <app-mini-loading *ngIf="loading.establishments"></app-mini-loading>
                </mat-label>
                <mat-select formControlName="establishment" (selectionChange)="onChangeEstablishment($event)">
                    <mat-option *ngFor="let e of establishments" [value]="e.id">{{e.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex="40%" fxFlex.lt-md="50%" fxFlex.xs="100%">
            <mat-form-field appearance="outline">
                <mat-label>{{description.solution.singular}}
                    <app-mini-loading *ngIf="loading.solutions"></app-mini-loading>
                </mat-label>
                <mat-select formControlName="solution" (selectionChange)="onChangeSolution($event)">
                    <mat-option *ngFor="let s of solutions" [value]="s.id">
                        <span *ngIf="s.id === -1">{{s.name}}</span>
                        <span *ngIf="s.id !== -1">{{s.code}} [{{s.product?.name}}
                            {{s.speed?.value}}
                            {{s.speed?.type}}]</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
</form>